import React, { useRef, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./slider.css";  // Import the custom styles

function Vacancies({ Notification }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentSyllabus, setCurrentSyllabus] = useState("");
  const scrollRef = useRef(null); // useRef for the scrollable container
  const sliderRef = useRef(null);

  const handleItemClick = (syllabusContent) => {
    setCurrentSyllabus(syllabusContent);
    setIsModalOpen(true);
  };

  
  // Function to scroll right
  const scrollRight = () => {
    scrollRef.current.scrollBy({ left: 300, behavior: "smooth" });
  };

  // Function to scroll left
  const scrollLeft = () => {
    scrollRef.current.scrollBy({ left: -300, behavior: "smooth" });
  };

  const SampleNextArrow = (props) => {
    const { className, onClick } = props;
    return (
      <div className={className} onClick={onClick}>
        <img src="assets/home/s-right.svg" alt="Next" />
      </div>
    );
  }
  
  const SamplePrevArrow = (props) => {
    const { className, onClick } = props;
    return (
      <div className={className} onClick={onClick}>
        <img src="assets/home/s-left.svg" alt="Previous" />
      </div>
    );
  }

  const NextArrow = (props) => {
    const { onClick } = props;
    return (
      <button
        className="slick-next"
        onClick={onClick}
      >
        <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1 13L7 7L1 1" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
      </button>
    );
  };

  const PrevArrow = (props) => {
    const { onClick } = props;
    return (
      <button
        className="slick-prev"
        onClick={onClick}
      >
        <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M7 13L1 7L7 1" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
      </button>
    );
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
    ref: sliderRef,
    nextArrow: <></>,
    prevArrow: <></>,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: true,
          dots: false
        }
      }
    ]
  };

  if (Notification?.length === 0) {
    return (
      <p className="text-center pt-[6px] font-sans font-[700] text-[18px] leading-[24px] text-[#000000]">
        Will share the results for the recent exams soon.
      </p>
    );
  }

  return (
    <>
    <div className="hidden md:block w-full">
      {Notification?.length === 0 ? (
          <p className="text-center pt-[6px] font-sans font-[700] text-[18px] leading-[24px] text-[#000000]">
            Will share the results for the recent exams soon.
          </p>
        ) : (
          <div className="relative">
          
            <div
              ref={scrollRef}
              className="flex flex-row md:flex-wrap overflow-x-auto space-x-4 md:space-x-0 no-scrollbar " scrollbar
              style={{ scrollBehavior: "smooth" ,margin :"auto" , display : 'flex' ,justifyContent : 'center'}}
            >
              {Notification?.map((e, i) => (
                <div
                  style={{
                    marginBottom: "8px",
                  }}
                  key={i}
                  className="flex-shrink-0"
                >
                  <div className="border overflow-hidden m-[4px] h-full cursor-pointer md:max-w-[218px] max-w-[300px] w-full rounded-[8px] border-[#D2D0D0] p-[6px]">
                    <div onClick={() => handleItemClick(e?.Syllabus)}>
                      {!e?.Img ? (
                        <img
                          src="assets/about/photo4.png"
                          className="w-full object-cover"
                          alt=""
                        
                        />
                      ) : (
                        <img
                          src={e?.Img}
                          className="w-full rounded-[10px] object-cover"
                            alt=""
                          
                        />
                      )}
                      <div className="pt-[30px]">
                        <div data-aos="fade-left">
                          <p className="text-center md:h-[80px] h-auto pt-[6px] font-sans font-[700] text-[18px] leading-[24px] text-[#000000]">
                            {e?.Name.length > 40
                              ? `${e?.Name.substring(0, 40)}...`
                              : e?.Name}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
                
              </div>
              
          </div>
        )}
    </div>
        <div className="md:hidden w-full md:px-0">
        <Slider {...settings}>
          {Notification?.map((e, i) => (
            <div key={i} className="w-full">
              <div className="w-full border overflow-hidden cursor-pointer rounded-[8px] border-[#D2D0D0] bg-white">
                <div onClick={() => handleItemClick(e?.Syllabus)} className="w-full">
                  <div className="h-[200px] w-full">
                    {!e?.Img ? (
                      <img
                        src="assets/about/photo4.png"
                        className="w-full h-full object-cover"
                        alt=""
                      />
                    ) : (
                      <img
                        src={e?.Img}
                        className="w-full h-full object-cover"
                        alt=""
                      />
                    )}
                  </div>
                  <div className="p-4">
                    <p className="text-center font-sans font-[700] text-[16px] leading-[22px] text-[#000000]">
                      {e?.Name}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Slider>
        <div className="flex  items-center justify-center w-full mt-[-19px]">
        <SamplePrevArrow
          className="arrow"
          onClick={() => sliderRef.current.slickPrev()}
        />
       
        <SampleNextArrow
          className="arrow"
          onClick={() => sliderRef.current.slickNext()} // Trigger next slide
        />
      </div>
      </div>

      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <div dangerouslySetInnerHTML={{ __html: currentSyllabus }} />
      </Modal>
    </>
  );
}

function Modal({ isOpen, onClose, children }) {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white rounded-lg shadow-lg p-6 w-full max-w-3xl">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-semibold">Details</h2>
          <button
            className="text-gray-600 hover:text-gray-800 focus:outline-none"
            onClick={onClose}
          >
            ×
          </button>
        </div>
        <div className="overflow-y-auto max-h-96">{children}</div>
      </div>
    </div>
  );
}

export default Vacancies;
